<template>
  <v-card flat class="my-6 mx-9 rounded-lg">
    <v-row>
      <v-col cols="6">
        <ImageScreeningCard src="image_15.png" class="my-4" />
        <ImageScreeningCard src="image_17.png" class="my-4" />
      </v-col>
      <v-col cols="6">
        <ImageScreeningCard src="image_16.png" class="my-4" />
        <ImageScreeningCard src="image_20.png" class="my-4" />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import ImageScreeningCard from "@/components/ImageScreeningCard";
export default {
  components: {
    ImageScreeningCard,
  },
};
</script>

<style lang="scss" scoped>
</style>
<template>
  <v-card flat class="d-flex" color="#f5f7f7">
    <div class="d-inline-block image-screening-photo">
      <v-img
        width="137"
        height="103"
        :src="require('@/assets/img/' + src)"
      ></v-img>

      <v-btn color="white" icon class="image-screening-icon">
        <v-icon>mdi-alert-circle-outline</v-icon>
      </v-btn>
    </div>

    <div class="d-inline-block image-screening-details">
      <div class="text-body-2 mt-2 ml-4">
        Photo has been
        <span class="font-weight-black">used in an earlier claim.</span>
      </div>

      <v-btn small text color="blue" class="ml-1 image-screening-footer"
        >show details</v-btn
      >
    </div>
  </v-card>
</template>

<script>
export default {
  props: ["src"],
};
</script>

<style lang="scss" scoped>
.image-screening-details,
.image-screening-photo {
  position: relative;
}

.image-screening-icon {
  position: absolute;
  bottom: 0;
  left: 0;
}

.image-screening-footer {
  position: absolute;
  bottom: 0;
}
</style>